import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
// ag grid pagination is 0-based, but we want to show 1-based in the URL
const DEFAULT_PAGE = 0;
const DEFAULT_URL_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;
function updateSearchParam(params, key, value, defaultValue) {
    if (value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        value === defaultValue) {
        params.delete(key);
    }
    else {
        params.set(key, typeof value === 'object' ? JSON.stringify(value) : value.toString());
    }
}
/**
 * Hook to handle persisting AG Grid state in URL parameters
 * Only persists non-default values to keep URLs clean
 */
export function useGridStatePersistence(options = {}) {
    const { enabled = true, initialState } = options;
    const [searchParams, setSearchParams] = useSearchParams();
    const gridState = useMemo(() => {
        const state = { ...initialState };
        const hasGridParams = ['sort', 'filter', 'page', 'pageSize'].some((key) => searchParams.has(key));
        if (!hasGridParams) {
            return state;
        }
        const sortParam = searchParams.get('sort');
        if (sortParam) {
            state.sort = JSON.parse(sortParam);
        }
        const filterParam = searchParams.get('filter');
        if (filterParam) {
            state.filter = JSON.parse(filterParam);
        }
        const pageSize = searchParams.has('pageSize')
            ? parseInt(searchParams.get('pageSize') || '20', 10)
            : DEFAULT_PAGE_SIZE;
        const page = searchParams.has('page')
            ? Math.max(0, parseInt(searchParams.get('page') || '1', 10) - 1) // Convert from 1-based to 0-based
            : DEFAULT_PAGE;
        state.pagination = { page, pageSize };
        return state;
    }, [searchParams, initialState]);
    const onStateUpdated = useCallback((event) => {
        const persistedParams = ['sort', 'filter', 'pagination'];
        if (!enabled ||
            !event.sources.filter((value) => persistedParams.includes(value)).length) {
            return;
        }
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            const { sort, filter, pagination } = event.state;
            updateSearchParam(newParams, 'sort', sort, null);
            updateSearchParam(newParams, 'filter', filter, null);
            if (pagination) {
                // Convert page to 1-based for URL
                const urlPage = pagination.page !== undefined
                    ? pagination.page + 1
                    : DEFAULT_URL_PAGE;
                updateSearchParam(newParams, 'page', urlPage, DEFAULT_URL_PAGE);
                updateSearchParam(newParams, 'pageSize', pagination.pageSize, DEFAULT_PAGE_SIZE);
            }
            else {
                newParams.delete('page');
                newParams.delete('pageSize');
            }
            return newParams;
        });
    }, [enabled, setSearchParams]);
    return {
        gridState,
        onStateUpdated,
    };
}
