import { FilterModel, GridState, SortModelItem } from 'ag-grid-enterprise';
import { SetURLSearchParams } from 'react-router-dom';

export const persistTableState = (
  state: GridState,
  setSearchParams: SetURLSearchParams
): void => {
  const filterModel = state.filter?.filterModel as FilterModel;
  const sortModel = state.sort?.sortModel[0] as SortModelItem;

  const matchKeyToFilter: Record<string, string> = {
    'ag-Grid-AutoColumn': 'searchWsis',
    association: 'associations',
    batch: 'batches',
    objectivePower: 'objectivePowers',
    scanner: 'scanners',
    staining: 'stainings',
    localization: 'tissues',
    disease: 'diseases',
    annotations: 'filterAnnotations',
    overlays: 'filterOverlays',
  };

  setSearchParams((prev) => {
    const newParams = new URLSearchParams('');
    const currentFilters = new URLSearchParams(prev);

    newParams.set('page', currentFilters.get('page') || '1');

    if (sortModel) {
      newParams.set('sortDirection', sortModel.sort || 'asc');

      newParams.set(
        'sortBy',
        sortModel.colId === 'ag-Grid-AutoColumn'
          ? 'name'
          : sortModel.colId || 'name'
      );
    }
    if (filterModel) {
      for (const [key, value] of Object.entries(filterModel)) {
        if (value.filterType === 'set') {
          newParams.set(matchKeyToFilter[key] ?? key, value.values);
        } else if (value.filterType === 'text') {
          newParams.set(matchKeyToFilter[key] ?? key, value.filter);
        }
      }
    }
    return newParams;
  });
};

export const persistGridState = (setSearchParams: SetURLSearchParams): void => {
  const matchKeyToFilter: Record<string, string> = {
    search: 'ag-Grid-AutoColumn',
    associations: 'association',
    batches: 'batch',
    objectivePowers: 'objectivePower',
    scanners: 'scanner',
    stainings: 'staining',
    tissues: 'localization',
    diseases: 'disease',
    filterAnnotations: 'annotations',
    filterOverlays: 'overlays',
    filterAnnotatedBy: 'annotatedBy',
    filterAnnotationCategory: 'annotationCategory',
  };
  setSearchParams((prev) => {
    const currentFilters = new URLSearchParams(prev);

    const params = [];

    for (const entry of currentFilters.entries()) {
      params.push(entry);
    }

    const filterModel: FilterModel = {};
    const sortColumn = currentFilters.get('sortBy');

    const sortModel: SortModelItem = {
      colId:
        sortColumn === 'name'
          ? 'ag-Grid-AutoColumn'
          : sortColumn ?? 'ag-Grid-AutoColumn',
      sort: (currentFilters.get('sortDirection') as 'asc' | 'desc') ?? 'asc',
    };

    params.forEach(([key, value]) => {
      if (key === 'searchWsis') {
        filterModel['ag-Grid-AutoColumn'] = generateTextModel(value);
      } else if (key === 'case') {
        filterModel[key] = generateTextModel(value);
      } else {
        if (value === 'all' || !matchKeyToFilter[key]) return undefined;
        filterModel[matchKeyToFilter[key]] = generateSetModel(value);
      }
    });

    const newParams = new URLSearchParams('');

    newParams.set('filter', JSON.stringify({ filterModel }));
    newParams.set('sort', JSON.stringify({ sortModel: [sortModel] }));
    newParams.set('page', currentFilters.get('page') || '1');

    return newParams;
  });
};

const generateTextModel = (value: string) => {
  return {
    filterType: 'text',
    type: 'contains',
    filter: value,
  };
};

const generateSetModel = (value: string) => {
  return {
    values: value.split(','),
    filterType: 'set',
  };
};
