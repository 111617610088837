import {
  PopUp,
  SelectSingle,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { ApolloCache, DefaultContext, useMutation } from '@apollo/client';
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement } from 'react';
import { ToggleStainingAction } from '../../EditStainingsModal/useEditStainings';
import { CoefficientChangeModalState } from '../AssignedSlidesTable.component';
import { AssignedSlidesTableType } from '../hooks/useSlidesList';
import { SelectStyle } from '../styles';
import { getMenuOptions } from '../utils/getRegistrationCoefficientsMenuOptions';
import { SET_CHILD_SLIDE_PARAMS_MUTATION } from '../utils/SET_CHILD_SLIDE_PARAMS_MUTATION';

export const RegistrationSelect = ({
  subprojectId,
  props: { data, api, node },
}: {
  subprojectId: string;
  props: CustomCellRendererProps<AssignedSlidesTableType, string>;
}): ReactElement => {
  const coefficientChangeModal =
    useDisclosure<CoefficientChangeModalState>(false);

  const { addSnackbar } = useSnackbarMutations();

  const [setVisible] = useMutation<
    void,
    { subprojectId: string; toggleStainingActions: ToggleStainingAction[] },
    DefaultContext,
    ApolloCache<unknown>
  >(SET_CHILD_SLIDE_PARAMS_MUTATION, {
    onCompleted: () => {
      addSnackbar({
        message: 'Image registration coefficient successfully updated.',
        type: 'success',
      });
    },
    onError: (error) => {
      addSnackbar({ message: error.message, type: 'error', closesAfter: 0 });
    },
  });

  const appliedRegistration = data?.appliedRegistration ?? null;

  if (data?.availableCoefficients) {
    return (
      <div style={{ width: '300px', zIndex: 2 }}>
        <SelectSingle
          key={data.id}
          id={data.id}
          value={appliedRegistration ?? null}
          selectStyle={SelectStyle}
          options={getMenuOptions(
            data.availableCoefficients,
            appliedRegistration
          )}
          onChange={(v) => {
            if (v?.value && data?.reference) {
              const registrationLabel = v.label;
              const reference = data?.reference;
              const registration = v.value;

              coefficientChangeModal.open({
                wsi: origin,
                coefficient: registrationLabel,
                onConfirm: async () => {
                  await setVisible({
                    mutation: SET_CHILD_SLIDE_PARAMS_MUTATION,
                    variables: {
                      subprojectId,
                      toggleStainingActions: [
                        {
                          stainingUuid: data.id,
                          baseWsiUuid: reference,
                          active: true,
                          registration,
                        },
                      ],
                    },
                  });

                  if (node.data) {
                    node.updateData({
                      ...node.data,
                      appliedRegistration: v.value,
                    });
                    api.redrawRows();
                  }
                },
              });
            }
          }}
        />

        {coefficientChangeModal.isOpen && (
          <PopUp
            isVisible={coefficientChangeModal.isOpen}
            title={'Image registration'}
            onClose={coefficientChangeModal.close}
            shouldCloseOnBackdropClick={true}
            primaryAction={{
              label: 'Continue',
              onClick: async () => {
                await coefficientChangeModal.data?.onConfirm();
                coefficientChangeModal.close();
              },
            }}
            secondaryAction={{
              label: 'Cancel',
              onClick: coefficientChangeModal.close,
            }}
          >
            <p style={{ margin: '0' }}>
              {`You will be applying image registration coefficient ${coefficientChangeModal.data?.coefficient}. Are you sure?`}
            </p>
          </PopUp>
        )}
      </div>
    );
  }
  return <></>;
};
