import {
  Button,
  DropdownMenu,
  HStack,
  Modal,
  ProgressBar,
  Section,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { buildClientWsiUrl, pluralize } from '@aignostics/utils';
import { useMutation, useQuery } from '@apollo/client';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { AdminSubProjectSlidesAssignment } from './AdminSubProjectSlidesAssignment';
import { AdminSubProjectSlidesImportForm } from './AdminSubProjectSlidesImportForm';

import { OrganizationRole } from '@aignostics/core';
import { GridApi } from 'ag-grid-enterprise';
import { useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { WsiMetadataModal } from '../../../../components';
import { prefix, useLayoutMode } from '../../../../hooks';
import {
  AnnotationFeatureType,
  RegistrationGroups,
  Wsi,
} from '../../../../types';
import { getAnnotationProgress } from '../../../../utils';
import { FETCH_SUBPROJECT_WSIS } from '../../FETCH_SUBPROJECT_WSIS';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from '../AdminSubProjectAssignInteractiveOverlays/FETCH_INTERACTIVE_OVERLAYS_MATRIX';
import { AssignedSlidesGrid } from './AssignedSlidesGrid';
import { AssignedSlidesTable } from './AssignedSlidesTable/AssignedSlidesTable.component';
import { AssignedSlidesTableType } from './AssignedSlidesTable/hooks/useSlidesList';
import {
  persistGridState,
  persistTableState,
} from './AssignedSlidesTable/utils/persistState';
import EditStainingsModal from './EditStainingsModal/EditStainingsModal.component';
import { FETCH_SUB_PROJECT_REGISTRATION_GROUPS } from './FETCH_SUB_PROJECT_REGISTRATION_GROUPS';
import { IMPORT_WSIS_FROM_SUB_PROJECT } from './IMPORT_WSIS_FROM_SUB_PROJECT.queries';
import { ImageRegistrationModal } from './ImageRegistrationModal/ImageRegistrationModal.component';
import { READ_SUBPROJECT_SLIDES } from './READ_SUBPROJECT_SLIDES.queries';
import { LIST_SUB_PROJECT_SLIDES } from './RemoveSlidesModal/RemoveSlides.query';
import RemoveSlidesModal from './RemoveSlidesModal/RemoveSlidesModal.component';

const AIGNOSTICS_ORGANIZATION_NAME = 'aignostics';

export const ADMIN_ASSIGNED_SLIDES_PAGE_SIZE = 20;

export type refType = {
  refetch: () => void;
  setPage: Dispatch<SetStateAction<number>> | ((page: number) => void);
  downloadReport: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  getTableApi?: () => GridApi<AssignedSlidesTableType>;
};
export type SubprojectData = {
  totalWsisCount: number;
  totalAnnotated: number;
};
const paramsSchema = z.object({
  projectId: z.string(),
});
/** Assigned Slides */
const AdminSubProjectSlides = ({
  subprojectId,
  userRole,
  organizationUuid,
  rasterTileServerUrl,
  getToken,
}: {
  subprojectId: string;
  userRole: OrganizationRole;
  organizationUuid: string;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const theme = useTheme();
  const ref = useRef<refType>(null);
  const [{ totalWsisCount, totalAnnotated }, setSubprojectData] =
    useState<SubprojectData>({ totalWsisCount: 0, totalAnnotated: 0 });
  const [, setSearchParams] = useSearchParams();

  const isViewer = userRole.roleName === 'viewer';
  const { addSnackbar } = useSnackbarMutations();
  const { projectId } = paramsSchema.parse(useParams());

  const showAssociations =
    userRole.organization.name === AIGNOSTICS_ORGANIZATION_NAME;

  const [importWsisFromSubProject, { loading: isImportSlidesLoading }] =
    useMutation(IMPORT_WSIS_FROM_SUB_PROJECT);

  const assignDialog = useDisclosure();
  const importSlidesDialog = useDisclosure();
  const removeSlidesDialog = useDisclosure(false);
  const editStainingsDialog = useDisclosure(false);
  const imageRegistrationModal = useDisclosure();
  const infoModalDisclosure = useDisclosure<Wsi>(false);

  const { data: subProjectRegistrationGroups } = useQuery<{
    subProject: {
      registrationGroups: RegistrationGroups;
      annotationFeature: AnnotationFeatureType;
      showCsv: boolean;
    };
  }>(FETCH_SUB_PROJECT_REGISTRATION_GROUPS, {
    skip: isViewer,
    variables: {
      subProjectId: subprojectId,
    },
  });
  const { layoutMode, LayoutSelector } = useLayoutMode(
    undefined,
    `layout-${subprojectId}`,
    (prev) => {
      if (prev === 'List') {
        if (!ref.current?.getTableApi) return;

        const state = ref.current?.getTableApi()?.getState();
        persistTableState(state, setSearchParams);
      } else {
        persistGridState(setSearchParams);
      }
    }
  );
  const isGridLayout = layoutMode === 'Grid';

  const refetch = ref.current?.refetch;
  const setPage = ref.current?.setPage;
  const downloadReport = ref.current?.downloadReport;

  const registrationGroups =
    subProjectRegistrationGroups?.subProject?.registrationGroups;

  const dropdownMenuActions = useMemo(() => {
    return registrationGroups?.length
      ? [
          {
            title: 'Import slides',
            id: 'import-slides',
            props: {
              small: true,
              onClick: importSlidesDialog.open,
              'aria-label': 'import-slides',
            },
          },
          {
            title: 'Remove slides',
            id: 'remove-slides',
            props: {
              small: true,
              onClick: removeSlidesDialog.open,
              disabled: totalWsisCount === 0,
              'aria-label': 'remove-slides',
            },
          },
          {
            title: 'Edit stainings',
            id: 'edit-stainings',
            props: {
              small: true,
              onClick: editStainingsDialog.open,
              disabled: totalWsisCount === 0,
              'aria-label': 'edit-stainings',
            },
          },
          {
            title: 'Image registration',
            id: 'image-registration',
            props: {
              small: true,
              onClick: imageRegistrationModal.open,
              disabled: totalWsisCount === 0,
              'aria-label': 'image-registration',
            },
          },
        ]
      : [
          {
            title: 'Import slides',
            id: 'import-slides',
            props: {
              small: true,
              onClick: importSlidesDialog.open,
              'aria-label': 'import-slides',
            },
          },
          {
            title: 'Remove slides',
            id: 'remove-slides',
            props: {
              small: true,
              onClick: removeSlidesDialog.open,
              disabled: totalWsisCount === 0,
              'aria-label': 'remove-slides',
            },
          },
          {
            title: 'Edit stainings',
            id: 'edit-stainings',
            props: {
              small: true,
              onClick: editStainingsDialog.open,
              disabled: totalWsisCount === 0,
              'aria-label': 'edit-stainings',
            },
          },
        ];
  }, [
    editStainingsDialog.open,
    imageRegistrationModal.open,
    importSlidesDialog.open,
    registrationGroups?.length,
    removeSlidesDialog.open,
    totalWsisCount,
  ]);

  const annoationProgress = useMemo(() => {
    const annotationFeature =
      subProjectRegistrationGroups?.subProject?.annotationFeature || 'OFF';

    const { progress, count, total } = getAnnotationProgress(
      totalAnnotated,
      totalWsisCount
    );

    return (
      <HStack spacing="large" alignItems="flex-end">
        {annotationFeature !== 'OFF' && (
          <ProgressBar
            value={progress}
            color="primary"
            label={`${count} of ${total} ${pluralize(
              'Slide',
              count
            )} annotated`}
          />
        )}
      </HStack>
    );
  }, [
    subProjectRegistrationGroups?.subProject?.annotationFeature,
    totalAnnotated,
    totalWsisCount,
  ]);

  const showCsv = subProjectRegistrationGroups?.subProject?.showCsv;

  const grid = useMemo(() => {
    return (
      <AssignedSlidesGrid
        ref={ref}
        organizationUuid={organizationUuid}
        projectId={projectId}
        subprojectId={subprojectId}
        infoModalDisclosure={infoModalDisclosure}
        rasterTileServerUrl={rasterTileServerUrl}
        getToken={getToken}
        userRole={userRole}
        assignDialog={assignDialog}
        importSlidesDialog={importSlidesDialog}
        setSubprojectData={setSubprojectData}
      >
        <HStack spacing="32" justifyContent="center" alignItems={'end'}>
          {annoationProgress}
          <HStack
            spacing="large"
            justifyContent="end"
            alignItems={'end'}
            style={{ width: '100%' }}
          >
            <DropdownMenu
              menuLabel={{
                title: 'Edit slides',
                props: { small: true, 'aria-label': 'edit-slides' },
              }}
              menuItems={dropdownMenuActions}
            />
            <Button small variant="primary" onClick={assignDialog.open}>
              Assign new slides
            </Button>
            {/* Show project report download buttons according to flags.*/}
            {showCsv && (
              <Button icon="Download" onClick={downloadReport} small>
                CSV
              </Button>
            )}
            <LayoutSelector key="wsi-view-type" name="wsi-view-type" />
          </HStack>
        </HStack>
      </AssignedSlidesGrid>
    );
  }, [
    LayoutSelector,
    annoationProgress,
    assignDialog,
    downloadReport,
    dropdownMenuActions,
    getToken,
    importSlidesDialog,
    infoModalDisclosure,
    organizationUuid,
    projectId,
    rasterTileServerUrl,
    showCsv,
    subprojectId,
    userRole,
  ]);
  const table = useMemo(() => {
    return (
      <>
        <HStack spacing="32" justifyContent="center" alignItems={'end'}>
          <HStack spacing="large" alignItems="flex-end">
            {annoationProgress}
          </HStack>
          <HStack
            spacing="large"
            justifyContent="end"
            alignItems={'end'}
            style={{ width: '100%' }}
          >
            <DropdownMenu
              menuLabel={{
                title: 'Edit slides',
                props: { small: true, 'aria-label': 'edit-slides' },
              }}
              menuItems={dropdownMenuActions}
            />
            <Button small variant="primary" onClick={assignDialog.open}>
              Assign new slides
            </Button>
            {/* Show project report download buttons according to flags.*/}
            {showCsv && (
              <Button icon="Download" onClick={downloadReport} small>
                CSV
              </Button>
            )}

            <LayoutSelector key="wsi-view-type" name="wsi-view-type" />
          </HStack>
        </HStack>
        <AssignedSlidesTable
          ref={ref}
          subprojectId={subprojectId}
          showMore={!isViewer}
          getWsiHref={(wsiUuid: string) =>
            buildClientWsiUrl(
              organizationUuid,
              projectId,
              subprojectId,
              wsiUuid
            )
          }
          role={userRole}
          getToken={getToken}
          rasterTileServerUrl={rasterTileServerUrl}
          setSubprojectData={setSubprojectData}
        />
      </>
    );
  }, [
    LayoutSelector,
    annoationProgress,
    assignDialog.open,
    downloadReport,
    dropdownMenuActions,
    getToken,
    isViewer,
    organizationUuid,
    projectId,
    rasterTileServerUrl,
    showCsv,
    subprojectId,
    userRole,
  ]);
  return (
    <Section loading={isImportSlidesLoading}>
      <>{isGridLayout ? grid : table}</>

      {!isViewer && (
        <>
          {removeSlidesDialog.isOpen && (
            <RemoveSlidesModal
              rasterTileServerUrl={rasterTileServerUrl}
              getToken={getToken}
              isVisible={removeSlidesDialog.isOpen}
              onClose={(shouldRefetchOnClose) => {
                removeSlidesDialog.close();
                if (shouldRefetchOnClose) {
                  sessionStorage.removeItem(
                    `${prefix}/${organizationUuid}/project/${projectId}/${subprojectId}`
                  );
                  setPage?.(1);
                  refetch?.();
                }
              }}
              showAssociations={showAssociations}
              subProjectId={subprojectId}
              pageSize={ADMIN_ASSIGNED_SLIDES_PAGE_SIZE}
            />
          )}

          {editStainingsDialog.isOpen && (
            <EditStainingsModal
              rasterTileServerUrl={rasterTileServerUrl}
              getToken={getToken}
              organizationUuid={organizationUuid}
              isVisible={editStainingsDialog.isOpen}
              onClose={() => {
                editStainingsDialog.close();
                refetch?.();
              }}
              showAssociations={showAssociations}
              subProjectId={subprojectId}
            />
          )}

          <Modal
            isVisible={assignDialog.isOpen}
            onClose={() => {
              assignDialog.close();
              refetch?.();
            }}
            shouldCloseOnEscKey
            hasCloseButton
            header={
              <h1
                id="assignSlidesTitle"
                style={{
                  ...theme.fontStyles.displayBold,
                  padding: `${theme.spacings['32']}px`,
                }}
              >
                Assign slides
              </h1>
            }
            size="max"
          >
            {subprojectId ? (
              <AdminSubProjectSlidesAssignment
                subprojectId={subprojectId}
                wsisCount={totalWsisCount ?? 0}
                showAssociations={showAssociations}
                rasterTileServerUrl={rasterTileServerUrl}
                getToken={getToken}
                organizationUuid={organizationUuid}
                userRole={userRole}
              />
            ) : null}
          </Modal>
          <ImageRegistrationModal
            onClose={imageRegistrationModal.close}
            isOpen={imageRegistrationModal.isOpen}
            subprojectId={subprojectId}
            registrationGroups={registrationGroups}
            slidesCount={totalWsisCount}
          />
          <Modal
            isVisible={importSlidesDialog.isOpen}
            onClose={importSlidesDialog.close}
            hasCloseButton
            size="small"
            aria-labelledby="importSlidesTitle"
            header={
              <h1
                id="importSlidesTitle"
                style={{
                  ...theme.fontStyles.displayBold,
                  padding: '32px 32px 0 32px',
                  margin: 0,
                }}
              >
                Import slides
              </h1>
            }
          >
            <div style={{ padding: '32px' }}>
              <AdminSubProjectSlidesImportForm
                subprojectId={subprojectId}
                onCancel={importSlidesDialog.close}
                onSubmit={(values) => {
                  importWsisFromSubProject({
                    variables: values,
                    refetchQueries: [
                      READ_SUBPROJECT_SLIDES,
                      FETCH_SUBPROJECT_WSIS,
                      LIST_SUB_PROJECT_SLIDES,
                      FETCH_INTERACTIVE_OVERLAYS_MATRIX,
                      FETCH_SUBPROJECT_WSIS,
                      FETCH_SUB_PROJECT_REGISTRATION_GROUPS,
                    ],
                    update: (cache) => {
                      // Add or remove wsi ref from parent subproject
                      cache.modify({
                        id: cache.identify({
                          __ref: `SubProject:${subprojectId}`,
                        }),
                        fields: {
                          wsis: (_value, { DELETE }) => {
                            return DELETE;
                          },
                        },
                      });
                    },
                  })
                    .then(() => {
                      refetch?.();
                      addSnackbar({
                        type: 'success',
                        message: `Slides from subproject ${values.srcSubProjectId} imported successfully`,
                      });
                      importSlidesDialog.close();
                    })
                    .catch((error) =>
                      addSnackbar({
                        type: 'error',
                        message: `Error importing slides from subproject ${values.srcSubProjectId}: ${error.message}`,
                      })
                    );
                }}
              />
            </div>
          </Modal>
        </>
      )}

      {infoModalDisclosure.data && (
        <WsiMetadataModal
          isOpen={infoModalDisclosure.isOpen}
          onClose={infoModalDisclosure.close}
          wsiId={infoModalDisclosure.data.id}
          subProjectId={subprojectId}
          rasterTileServerUrl={rasterTileServerUrl}
          role={userRole}
          getToken={getToken}
        />
      )}
    </Section>
  );
};

export default AdminSubProjectSlides;
