"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AdminRole = {
    'admin:access': true,
    'analytics:access': true,
    'annotation:readAll': true,
    'interactiveOverlay:deleteDev': true,
    'interactiveOverlay:deleteProd': true,
    'project:create': true,
    'project:delete': true,
    'project:edit': true,
    'project:readAll': true,
    'project:manage': true,
    'server:debug': true,
    'subproject:create': true,
    'subproject:delete': true,
    'subproject:edit': true,
    'subproject:manage': true,
    'subproject:readAll': true,
    'user:create': true,
    'user:delete': true,
    'user:edit': true,
    'user:impersonate': true,
    'user:readAll': true,
    'user:toggleAdmin': true,
    'wsi:edit': true,
    'wsi:readAll': true,
    'wsi:upload': true,
    'preset:create': true,
    'preset:readAll': true,
    'preset:editAll': true,
    'preset:edit': true,
    'preset:deleteAll': true,
    'preset:delete': true,
};
exports.default = AdminRole;
