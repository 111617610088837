import {
  Button,
  Input,
  Modal,
  SelectSingle,
  VisibleWithScope,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { Success } from '@aignostics/icons';
import React, { FormEvent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  $AssignBatchForm,
  $ContentWrapper,
  $ModalSubTitle,
  $ModalTitle,
  $SuccessSubTitle,
  $SuccessTitle,
  $SuccessWrapper,
} from './AssignBatchModal.styles';
import useAssignBatchToProject from './useAssignBatchToProject';

type AssignBatchModalProps = {
  isOpen: boolean;
  batchId: string | null;
  onCloseModal: () => void;
  role: OrganizationRole;
  currentUser: User;
};

export const AssignBatchModal = ({
  isOpen,
  onCloseModal,
  batchId,
  role,
  currentUser,
}: AssignBatchModalProps): ReactElement => {
  const navigate = useNavigate();

  const {
    assignToProject,
    changeProject,
    setProjectName,
    setSubProjectName,
    resetState,
    toggleCreateSubProject,
    toggleCreateProject,
    changeSubProject,
    subProjectsData: { subProjectsList, loadingSubProjects },
    projectsData: { projectsList, loadingProjects },
    state: { assignmentComplete, project, subProject, isSubmitting },
  } = useAssignBatchToProject(batchId, currentUser);

  const isValidProject =
    (project.isNew && project.name !== null) ||
    (!project.isNew && project.id !== null);

  const isValidSubProject =
    (subProject.isNew && subProject.name !== null) ||
    (!subProject.isNew && subProject.id !== null);

  const isAssignDisabled =
    isSubmitting || !isValidProject || !isValidSubProject;

  const onClose = () => {
    resetState();
    onCloseModal();
  };

  if (assignmentComplete) {
    return (
      <Modal isVisible={isOpen} hasCloseButton onClose={onClose} size="small">
        <$SuccessWrapper>
          <Success
            size={68}
            style={{ color: '#6FCC52', marginBottom: '24px' }}
          />
          <$SuccessTitle>Batch successfully assigned</$SuccessTitle>
          <$SuccessSubTitle>What would you like to do next?</$SuccessSubTitle>

          <div>
            <Button
              type="button"
              onClick={() => {
                void navigate(
                  `/${role.organization.uuid}/admin/projects/${project.id}/subproject/${subProject.id}/wsis?page=1`
                );
              }}
            >
              Go to Project
            </Button>
            <Button
              type="button"
              variant="primaryOutline"
              style={{ marginLeft: '16px' }}
              onClick={onClose}
            >
              Stay in Onboarding
            </Button>
          </div>
        </$SuccessWrapper>
      </Modal>
    );
  }

  return (
    <Modal
      aria-label="Assign this batch to project"
      isVisible={isOpen}
      hasCloseButton
      onClose={onClose}
      size="small"
    >
      <$ContentWrapper>
        <$ModalTitle>Assign this batch to project</$ModalTitle>
        <$ModalSubTitle>
          You need to choose or create the container project and then a
          subproject to receive the slides.
        </$ModalSubTitle>

        <$AssignBatchForm
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            void assignToProject();
          }}
        >
          <div>
            {project.isNew ? (
              <Input
                autoFocus
                value={project.name ?? ''}
                id={'project'}
                label="Project Name"
                onChange={(p) => {
                  setProjectName(p);
                }}
                aria-label="New Project Name"
              />
            ) : (
              <SelectSingle
                id="name"
                label="Project Name"
                value={project.id}
                menuPosition="fixed"
                onChange={(data) => {
                  if (data?.value) changeProject(data.value);
                  else {
                    changeProject(null);
                  }
                }}
                options={loadingProjects ? [] : projectsList}
              />
            )}
            <VisibleWithScope
              role={role}
              scope="project:create"
              key="createProject"
            >
              <Button
                variant="link"
                type="button"
                onClick={toggleCreateProject}
              >
                {project.isNew
                  ? 'Assign to existing project'
                  : 'Create a new project'}
              </Button>
            </VisibleWithScope>
          </div>
          <div>
            {subProject.isNew ? (
              <Input
                value={subProject.name ?? ''}
                id={'subProject'}
                label="Subproject Name"
                onChange={setSubProjectName}
                aria-label="New Subproject Name"
              />
            ) : (
              <SelectSingle
                id="name"
                label="Subproject Name"
                value={subProject.id}
                disabled={!project}
                menuPosition="fixed"
                onChange={(d) => {
                  changeSubProject(d?.value ?? null);
                }}
                options={loadingSubProjects ? [] : subProjectsList}
              />
            )}
            <VisibleWithScope
              role={role}
              scope="subproject:create"
              key="createSubproject"
            >
              <Button
                variant="link"
                type="button"
                disabled={project.isNew}
                onClick={toggleCreateSubProject}
              >
                {subProject.isNew
                  ? 'Assign to existing sub project'
                  : 'Create a new sub project'}
              </Button>
            </VisibleWithScope>
          </div>
          <div>
            <Button type="submit" disabled={isAssignDisabled}>
              Assign
            </Button>
            <Button
              type="button"
              variant="primaryOutline"
              style={{ marginLeft: '16px' }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </$AssignBatchForm>
      </$ContentWrapper>
    </Modal>
  );
};
