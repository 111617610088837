import {
  Flex,
  Icon,
  Modal,
  RadixTable,
  useDisclosure,
} from '@aignostics/components';

import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { METADATA_USAGE_RESTRICTIONS } from '../../../utils/metadataUsageRestrictions';

const ContentWrapper = styled.div`
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.IPAD_PORTRAIT_MAX}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings.button}px;
`;

const ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  text-align: left;
  margin-bottom: 48px;
`;

const $Icon = styled(Icon)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacings[4]}px;
`;

const rowData = Object.values(METADATA_USAGE_RESTRICTIONS).map(
  (restriction) => ({
    restriction: restriction.label,
    description: restriction.description,
  })
);

export function MetadataUsageRestrictionInfo(): ReactElement {
  const infoModal = useDisclosure();

  return (
    <Flex>
      <$Icon
        icon="Info"
        size="input"
        onClick={infoModal.open}
        aria-label="metadata-usage-restriction-info"
      />

      {/*Modal*/}
      <Modal
        shouldCloseOnEscKey
        hasCloseButton
        aria-label="Data Usage Restrictions Info Modal"
        isVisible={infoModal.isOpen}
        onClose={infoModal.close}
      >
        <ContentWrapper>
          <ModalTitle>Data Usage Restrictions</ModalTitle>
          <RadixTable.Root>
            <RadixTable.Header>
              <RadixTable.Row>
                <RadixTable.ColumnHeaderCell>
                  Restriction
                </RadixTable.ColumnHeaderCell>
                <RadixTable.ColumnHeaderCell>
                  Description
                </RadixTable.ColumnHeaderCell>
              </RadixTable.Row>
            </RadixTable.Header>

            <RadixTable.Body>
              {rowData.map((row) => (
                <RadixTable.Row key={row.restriction}>
                  <RadixTable.Cell>{row.restriction}</RadixTable.Cell>
                  <RadixTable.Cell>{row.description}</RadixTable.Cell>
                </RadixTable.Row>
              ))}
            </RadixTable.Body>
          </RadixTable.Root>
        </ContentWrapper>
      </Modal>
    </Flex>
  );
}
