"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ViewerRole = {
    'admin:access': false,
    'analytics:access': false,
    'annotation:readAll': false,
    'interactiveOverlay:deleteDev': false,
    'interactiveOverlay:deleteProd': false,
    'project:create': false,
    'project:delete': false,
    'project:edit': false,
    'project:readAll': false,
    'project:manage': false,
    'server:debug': false,
    'subproject:create': false,
    'subproject:delete': false,
    'subproject:edit': false,
    'subproject:readAll': false,
    'subproject:manage': false,
    'user:create': false,
    'user:delete': false,
    'user:edit': false,
    'user:impersonate': false,
    'user:readAll': false,
    'user:toggleAdmin': false,
    'wsi:edit': false,
    'wsi:readAll': false,
    'wsi:upload': false,
    'preset:create': false,
    'preset:readAll': true,
    'preset:editAll': false,
    'preset:edit': false,
    'preset:deleteAll': false,
    'preset:delete': false,
};
exports.default = ViewerRole;
