import { getAgGridPaginationConfig } from '@aignostics/components';
import { ApolloClient, DocumentNode } from '@apollo/client';
import {
  FilterModel,
  IServerSideDatasource,
  TextFilterModel,
} from 'ag-grid-enterprise';
import { unionBy } from 'lodash';
import { Wsi } from '../../../../../../types';
import {
  AvailableCoefficientsType,
  RelatedLayersType,
} from '../../AdminSubprojectSlides.type';
import { GET_AVAILABLE_COEFFICIENTS } from '../utils/GET_AVAILABLE_COEFFICIENTS';
import { GET_RELATED_LAYERS } from '../utils/GET_RELATED_LAYERS';

export type AssignedSlidesTableType = {
  id: string;
  name: string;
  association: string;
  batch: string;
  case: string;
  objectivePower: number;
  localization: string;
  scanner: { vendor: string; model: string };
  staining: string;
  regions: number;
  brightField: number;
  fluorescence: number;
  annotations: number;
  taggers: number;
  overlays: number;
  level: number;
  disease: string;
  annotatedBy: number;
  annotationCategory: number;
  isFluorescence: boolean;
  totalAnnotated?: number;
  totalWsisCount?: number;
  wsiData?: Wsi;
  appliedRegistration?: string;
  availableCoefficients?: {
    registrationId: string;
    registrationName: string;
    referenceId: string;
  }[];
  reference?: string;
};

const emptyWsi = {
  id: '',
  name: '',
  regions: 0,
  brightField: 0,
  localization: '',
  fluorescence: 0,
  annotations: 0,
  taggers: 0,
  overlays: 0,
  association: '',
  batch: '',
  case: '',
  objectivePower: 0,
  scanner: { model: '', vendor: '' },
  staining: '',
  disease: '',
  level: 0,
  annotatedBy: 0,
  annotationCategory: 0,
};

const groupArrayBy = <T extends { [key: string]: string }>(
  array: T[],
  key: string
): Record<string, T[]> => {
  return array.reduce<Record<string, T[]>>(function (r, a) {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create({}));
};

export const createServerSideDatasource = (
  client: ApolloClient<object>,
  queryDocument: DocumentNode,
  subProjectId: string
): IServerSideDatasource<AssignedSlidesTableType> => {
  return {
    getRows: async (params) => {
      try {
        const {
          startRow: maybeStartRow,
          endRow: maybeEndRow,
          sortModel,
          groupKeys,
          filterModel,
        } = params.request;

        const { page, pageSize } = getAgGridPaginationConfig(
          maybeStartRow,
          maybeEndRow
        );

        const fm = filterModel as FilterModel;

        const extractTextFilter = (model?: TextFilterModel): string => {
          return model?.filterType === 'text' ? model.filter ?? '' : '';
        };

        const search = extractTextFilter(fm['ag-Grid-AutoColumn']);

        const caseFilter = extractTextFilter(fm['case']);
        const variables = {
          subProjectId,
          page,
          pageSize,
          sortBy:
            sortModel?.[0]?.colId === 'ag-Grid-AutoColumn'
              ? 'name'
              : sortModel?.[0]?.colId || 'name',
          isAsc: (sortModel?.[0]?.sort || 'asc') === 'asc',
          search,
          associations: fm['association']?.values ?? [],
          batches: fm['batch']?.values ?? [],
          case: caseFilter,
          objectivePowers:
            fm['objectivePower']?.values.map((value: string) =>
              Number(value)
            ) ?? [],
          scanners: fm['scanner']?.values ?? [],
          stainings: fm['staining']?.values ?? [],
          tissues: fm['localization']?.values ?? [],
          diseases: fm['disease']?.values ?? [],
          annotations: fm['annotations']?.values?.[0] || 'all',
          annotatedBy: fm['annotatedBy']?.values ?? [],
          annotationCategory: fm['annotationCategory']?.values ?? [],
          overlays: fm['overlays']?.values?.[0] || 'all',
        };

        if (groupKeys && groupKeys.length > 0) {
          const wsiId = groupKeys[0];

          const { data, error } = await client.query<RelatedLayersType>({
            query: GET_RELATED_LAYERS,
            variables: { subProjectId, wsiId },
            fetchPolicy: 'network-only',
          });

          const {
            data: availableCoefficientsData,
            error: availableCoefficientsError,
          } = await client.query<AvailableCoefficientsType>({
            query: GET_AVAILABLE_COEFFICIENTS,
            variables: { subProjectId, wsiId },
            fetchPolicy: 'network-only',
          });

          if (error || availableCoefficientsError) {
            params.fail();
            return;
          }

          const stainingCoefficients =
            availableCoefficientsData?.availableCoefficients.stainings &&
            groupArrayBy(
              availableCoefficientsData?.availableCoefficients.stainings,
              'referenceId'
            );

          const multiplexCoefficients =
            availableCoefficientsData?.availableCoefficients.fluorescence &&
            groupArrayBy(
              unionBy(
                availableCoefficientsData?.availableCoefficients.fluorescence,
                'registrationId'
              ),
              'referenceId'
            );

          const stainingRows = data.relatedLayers.stainings
            .filter(({ isActive }) => isActive)
            .map((staining) => {
              return {
                ...emptyWsi,
                level: 1,
                id: staining.id,
                name: staining.stainingName,
                scanner: staining.scanner,
                isFluorescence: false,
                availableCoefficients: stainingCoefficients[staining.id],
                appliedRegistration:
                  stainingCoefficients[staining.id]?.[0].appliedRegistration,
                reference: wsiId,
              };
            });
          const fluorescenceRows = data.relatedLayers.fluorescence
            .filter(({ isActive }) => isActive)
            .map((fluorescence) => {
              return {
                ...emptyWsi,
                level: 1,
                id: fluorescence.firstChannel || fluorescence.id,
                name: `miF ${fluorescence.name} (${fluorescence.channelsCount} channels)`,
                isFluorescence: true,
                availableCoefficients: multiplexCoefficients[fluorescence.id],
                reference: wsiId,
                appliedRegistration:
                  stainingCoefficients[fluorescence.id]?.[0]
                    .appliedRegistration,
              };
            });

          params.success({
            rowData: [...stainingRows, ...fluorescenceRows],
            rowCount: stainingRows.length + fluorescenceRows.length,
          });
          return;
        }

        const { data, error } = await client.query({
          query: queryDocument,
          variables,
          fetchPolicy: 'network-only',
        });

        if (error) {
          params.fail();
          return;
        }

        const rawData =
          data?.subProject?.wsis.nodes.map((wsi: Wsi) => ({
            id: wsi.id,
            name: wsi.name,
            regions: wsi.regionsOfInterestCount,
            brightField: wsi.brightfieldCount,
            fluorescence: wsi.fluorescenceCount,
            annotations: wsi.annotationsCount,
            taggers: wsi.taggersCount,
            overlays: wsi.overlaysCount,
            association: wsi.association,
            batch: wsi.batchName,
            case: wsi.case.name,
            objectivePower: wsi.objectivePower,
            scanner: wsi.scanner,
            staining: wsi.staining,
            disease: wsi.disease,
            localization: wsi.localization,
            level: 0,
            annotatedBy: wsi.annotationsData?.annotatedByCount,
            annotationCategory: wsi.annotationsData?.annotatedCategoriesCount,
            wsiData: { ...wsi },
            isFluorescence: false,
            totalAnnotated:
              data?.subProject?.wsis?.collectionAttributes?.totalAnnotated,
            totalWsisCount: data?.subProject?.wsis.pageInfo?.totalElements,
          })) ?? [];

        const totalElements =
          data?.subProject?.wsis.pageInfo?.totalElements ?? rawData.length;

        params.success({ rowData: rawData, rowCount: totalElements });
      } catch {
        params.fail();
      }
    },
  };
};
