"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const DeveloperRole = {
    'admin:access': true,
    'analytics:access': true,
    'annotation:readAll': true,
    'interactiveOverlay:deleteDev': true,
    'interactiveOverlay:deleteProd': false,
    'project:create': false,
    'project:delete': false,
    'project:edit': false,
    'project:manage': false,
    'project:readAll': false,
    'server:debug': true,
    'subproject:create': true,
    'subproject:delete': false,
    'subproject:edit': true,
    'subproject:manage': true,
    'subproject:readAll': false,
    'user:create': false,
    'user:delete': false,
    'user:edit': false,
    'user:impersonate': false,
    'user:readAll': false,
    'user:toggleAdmin': false,
    'wsi:edit': true,
    'wsi:readAll': false,
    'wsi:upload': true,
    'preset:create': true,
    'preset:readAll': true,
    'preset:editAll': true,
    'preset:edit': true,
    'preset:deleteAll': true,
    'preset:delete': true,
};
exports.default = DeveloperRole;
