import {
  $FilterControls,
  $FilterHeader,
  $FilterSearch,
  Button,
  Filter,
  FilterField,
  Input,
  Loader,
  SpreadFilterProps,
  isTouchDevice,
  useSnackbarMutations,
} from '@aignostics/components';
import { gql, useLazyQuery } from '@apollo/client';
import React, { ReactElement, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { OrganizationRole } from '@aignostics/core';
import {
  Association,
  Batch,
  Disease,
  Scanner,
  Staining,
  SubProject,
  Tissue,
} from '../../../types';
import {
  FilterKeysWithAssociation,
  FilterKeysWithAssociationWithSlidesFilter,
  FilterKeysWithoutAssociation,
  FilterKeysWithoutAssociationWithSlidesFilter,
} from './AdminSubProjectSlides/AdminSubprojectSlides.type';

export const FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS = gql`
  query GET_ADMIN_SUBPROJECT_WSIS_FILTERS(
    $associations: [ID]!
    $subProjectId: ID!
  ) {
    subProject(id: $subProjectId) {
      id
      annotationFeature
      overlaysCount
      annotationCategories {
        id
        name
      }
      annotators {
        ... on Annotator {
          id
          name
          email
        }
        ... on AnonymousAnnotator {
          id
          name
          email
        }
      }
    }
    associations {
      uuid
      id
      name
    }
    batches(associations: $associations) {
      uuid
      id
      name
    }
    stainings {
      uuid
      id
      name
    }
    tissues {
      uuid
      id
      name
    }
    scanners {
      uuid
      id
      vendor
      model
    }
    diseases {
      name
    }
  }
`;

type FilterKeysSlidesList =
  | 'filterAnnotations'
  | 'filterOverlays'
  | 'filterAnnotatedBy'
  | 'filterAnnotationCategory';

const generateSlidesListPageFilters = ({
  subProject,
  userRole,
}: {
  subProject: SubProject | undefined;
  userRole?: OrganizationRole;
}): Record<FilterKeysSlidesList, FilterField> => {
  const hasOverlays = Number(subProject?.overlaysCount) > 0;
  const shouldFilterAnnotations = subProject?.annotationFeature !== 'OFF';
  const shouldFilterAnnotatedBy = Boolean(
    userRole?.scopes['annotation:readAll'] && shouldFilterAnnotations
  );

  return {
    filterAnnotations: {
      type: 'radio',
      label: 'Annotations',
      value: 'all',
      options: [
        { value: 'all', label: 'All' },
        { value: 'with', label: 'With' },
        { value: 'without', label: 'Without' },
      ],
      isEnabled: shouldFilterAnnotations,
    },
    filterOverlays: {
      type: 'radio',
      label: 'Overlays',
      value: 'all',
      options: [
        { value: 'all', label: 'All' },
        { value: 'with', label: 'With' },
        { value: 'without', label: 'Without' },
      ],
      isEnabled: hasOverlays,
    },
    filterAnnotatedBy: {
      type: 'multiselect',
      label: 'Annotated By',
      value: [],
      options: [
        ...(subProject?.annotators?.map((annotator) => ({
          value: annotator.id,
          label: annotator.name ?? annotator.email,
        })) ?? []),
      ],
      isEnabled: shouldFilterAnnotatedBy,
    } as FilterField,
    filterAnnotationCategory: {
      type: 'multiselect',
      label: 'Annotation Category',
      value: [],
      options: [
        ...(subProject?.annotationCategories?.map((category) => ({
          value: category.id,
          label: category.name,
        })) ?? []),
      ],
      isEnabled: shouldFilterAnnotations,
    },
  };
};

const generatePageFilters = ({
  showAssociations,
  associations,
  batches,
  scanners,
  stainings,
  tissues,
  diseases,
}: {
  showAssociations: boolean;
  associations: Association[];
  batches: Batch[];
  scanners: Scanner[];
  stainings: Staining[];
  tissues: Tissue[];
  diseases: Disease[];
}):
  | Record<FilterKeysWithoutAssociation, FilterField>
  | Record<FilterKeysWithAssociation, FilterField> => {
  return {
    searchWsis: {
      type: 'search',
      label: 'Search',
      placeholder: 'Search slides',
      value: '',
    },
    ...(showAssociations
      ? {
          associations: {
            type: 'multiselect',
            label: 'Association',
            value: [],
            options: associations.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            })),
          },
        }
      : {}),
    batches: {
      type: 'multiselect',
      label: 'Batch',
      value: [],
      options: batches.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    },
    caseId: {
      type: 'text',
      label: 'CaseID',
      value: '',
    },
    objectivePowers: {
      type: 'multiselect',
      label: 'Objective Power',
      value: [],
      options: [
        { label: '20', value: '20' },
        { label: '40', value: '40' },
      ],
    },
    scanners: {
      type: 'multiselect',
      label: 'Scanner',
      value: [],
      options: scanners.map(({ vendor, model, uuid }) => ({
        label: `${vendor} ${model ?? ''}`,
        value: uuid,
      })),
    },
    stainings: {
      type: 'multiselect',
      label: 'Staining',
      value: [],
      options: stainings.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    },
    tissues: {
      type: 'multiselect',
      label: 'Localization',
      value: [],
      options: tissues.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    },
    diseases: {
      type: 'multiselect',
      label: 'Disease',
      value: [],
      options: diseases.map(({ name }) => ({
        label: name,
        value: name,
      })),
    },
  };
};

export interface AdminSubProjectWsisFilters {
  associations: Association[];
  batches: Batch[];
  stainings: Staining[];
  tissues: Tissue[];
  scanners: Scanner[];
  diseases: Disease[];
  subProject: SubProject;
}

export interface AdminSubProjectWsisFiltersArgs {
  subProjectId: string;
  associations: Array<Pick<Association, 'name'>>;
}

export const AdminSubProjectWsisFilters = ({
  subprojectId,
  filterProps,
  showAssociations,
  onChange,
  userRole,
  withSlidesListFilters = true,
  pageId = '',
}: {
  subprojectId: string;
  filterProps:
    | SpreadFilterProps<FilterKeysWithoutAssociation>
    | SpreadFilterProps<FilterKeysWithAssociation>
    | SpreadFilterProps<FilterKeysWithoutAssociationWithSlidesFilter>
    | SpreadFilterProps<FilterKeysWithAssociationWithSlidesFilter>;
  showAssociations: boolean;
  onChange: (updates: Record<string, string | string[]>) => void;
  userRole?: OrganizationRole;
  withSlidesListFilters?: boolean;
  pageId?: string;
}): ReactElement => {
  const theme = useTheme();
  const { addSnackbar } = useSnackbarMutations();
  const [
    fetchAdminSubProjectWsisFilters,
    { data, previousData, loading, error },
  ] = useLazyQuery<AdminSubProjectWsisFilters, AdminSubProjectWsisFiltersArgs>(
    FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
    {
      variables: {
        subProjectId: subprojectId,
        associations: [],
      },
      onError: (error) => {
        addSnackbar({
          message: error.message,
          type: 'error',
          closesAfter: 0,
        });
      },
    }
  );

  useEffect(() => {
    if (!filterProps.isDefault) {
      void fetchAdminSubProjectWsisFilters();
    }
  }, [filterProps.isDefault, fetchAdminSubProjectWsisFilters]);

  const associations = (data || previousData)?.associations ?? [];
  const batches = (data || previousData)?.batches ?? [];
  const scanners = (data || previousData)?.scanners ?? [];
  const stainings = (data || previousData)?.stainings ?? [];
  const tissues = (data || previousData)?.tissues ?? [];
  const diseases = (data || previousData)?.diseases ?? [];

  const PAGE_FILTER_FIELDS:
    | Record<FilterKeysWithoutAssociation, FilterField>
    | Record<FilterKeysWithAssociation, FilterField>
    | Record<FilterKeysWithoutAssociationWithSlidesFilter, FilterField>
    | Record<FilterKeysWithAssociationWithSlidesFilter, FilterField> =
    generatePageFilters({
      showAssociations,
      associations,
      batches,
      scanners,
      stainings,
      tissues,
      diseases,
    });

  const slidesListFilters = withSlidesListFilters
    ? generateSlidesListPageFilters({ subProject: data?.subProject, userRole })
    : {};

  const handleFieldChange = (fieldValue: string | string[]) => {
    onChange({ ...filterProps.value, searchWsis: fieldValue });
  };

  return (
    <>
      {!data && (
        <$FilterHeader>
          <$FilterControls>
            <Button
              small
              icon={loading ? 'ChevronUp' : 'Union'}
              onClick={() => {
                void fetchAdminSubProjectWsisFilters();
              }}
              variant="white"
              aria-expanded={loading}
            >
              Filter
            </Button>

            {!filterProps.isDefault && (
              <Button
                small
                onClick={filterProps.onReset}
                icon="XCircle"
                variant="black"
              >
                Clear filters
              </Button>
            )}
          </$FilterControls>

          <$FilterSearch>
            <Input
              style={{ width: theme.spacings.tile }}
              icon={PAGE_FILTER_FIELDS.searchWsis.icon}
              innerIcon="Search"
              id={PAGE_FILTER_FIELDS.searchWsis.type}
              type="search"
              value={String(filterProps.value['searchWsis'])}
              onChange={handleFieldChange}
              placeholder={PAGE_FILTER_FIELDS.searchWsis.placeholder}
              name="searchField"
              aria-label={PAGE_FILTER_FIELDS.searchWsis.label}
              autoFocus={!isTouchDevice()}
              data-autofocus={!isTouchDevice()}
            />
          </$FilterSearch>
        </$FilterHeader>
      )}
      {loading ? <Loader /> : null}
      {data && !loading && !error && (
        <Filter
          title="Filter"
          fields={{ ...PAGE_FILTER_FIELDS, ...slidesListFilters }}
          {...filterProps}
          onChange={onChange}
          isOpen={true}
          pageId={pageId}
        />
      )}
    </>
  );
};
