import { Dialog, Input, useSnackbarMutations } from '@aignostics/components';
import { useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { cacheDeleteSubProject } from '../../../../utils';
import { DELETE_SUB_PROJECT } from './DELETE_SUBPROJECT.queries';
import { $Instructions, $List } from './DeleteSubprojectModal.styles';

export interface DeleteSubprojectModalProps {
  projectId: string;
  subProjectId: string;
  subprojectName: string;
  organizationUuid: string;
  hasSlidesAssigned: boolean;
  hasAnnotationsAssigned: boolean;
  hasAnnotationCategoriesAssigned: boolean;
  isVisible: boolean;
  onClose: () => void;
}

const DeleteSubprojectModal = ({
  projectId,
  subProjectId,
  subprojectName,
  organizationUuid,
  hasAnnotationCategoriesAssigned,
  hasAnnotationsAssigned,
  hasSlidesAssigned,
  onClose,
  isVisible,
}: DeleteSubprojectModalProps): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string>('');
  const [inputError, setInputError] = useState<string>('');

  const { addSnackbar } = useSnackbarMutations();
  const [deleteSubproject] = useMutation<unknown, { id: string }>(
    DELETE_SUB_PROJECT,
    {
      onError: (error) => {
        addSnackbar({ type: 'error', message: error.message });
      },
    }
  );

  const handleInputChange = (fieldValue: string) => {
    setInputValue(fieldValue);
    if (inputError) setInputError('');
  };

  const handleSubmit = () => {
    if (inputValue === '') {
      setInputError('Subproject name is required');
      return;
    }
    if (inputValue === subprojectName) {
      void deleteSubproject({
        variables: { id: subProjectId },
        update: cacheDeleteSubProject(projectId),
      }).then(() => {
        void navigate(`/${organizationUuid}/project/${projectId}`);
      });
    }
  };

  const handleInputBlur = () => {
    if (inputValue !== subprojectName) {
      setInputError('Subproject name does not match the entered value');
    } else {
      setInputError('');
    }
  };

  return (
    <Dialog
      isVisible={isVisible}
      title="Delete Subproject"
      onClose={onClose}
      primaryAction={{
        label: 'Submit',
        onClick: handleSubmit,
      }}
    >
      <p>You are about to delete a subproject which contains:</p>
      <$List>
        {hasAnnotationsAssigned && <li>Created annotations</li>}
        {hasAnnotationCategoriesAssigned && (
          <li>Assigned annotation categories</li>
        )}
        {hasSlidesAssigned && <li>Assigned slides</li>}
      </$List>
      <$Instructions>
        By confirming deletion, we will unassign slides and annotation
        categories and permanently delete annotations from this subproject. This
        does not affect any other subprojects which use the same slides,
        annotations or annotation categories
      </$Instructions>
      <$Instructions>
        If you are sure you want to do this, please enter the subproject name
        and click submit.
      </$Instructions>
      <Input
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        id="confirmSubprojectName"
        placeholder="Subproject Name"
        style={{ marginTop: theme.spacings[12] }}
        errorMessage={inputError}
        isInvalid={Boolean(inputError)}
      />
    </Dialog>
  );
};

export default DeleteSubprojectModal;
