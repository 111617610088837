import {
  AGGridTable,
  Button,
  Modal,
  useSnackbarMutations,
} from '@aignostics/components';
import { useMutation } from '@apollo/client';
import { ColDef } from 'ag-grid-enterprise';
import React, { ReactElement, useMemo, useState } from 'react';
import { RegistrationGroups } from '../../../../../types';
import { READ_SUBPROJECT_SLIDES } from '../READ_SUBPROJECT_SLIDES.queries';
import { SET_SUB_PROJECT_REGISTRATION_MUTATION } from '../SET_SUB_PROJECT_REGISTRATION_MUTATION';
import {
  $ButtonsContainer,
  $ModalTitle,
  $ModalWrapper,
} from './ImageRegistrationModal.styles';
import {
  getImageRegistrationModalColDef,
  RegistrationRow,
} from './utils/getImageRegistrationModalColDef';

interface ImageRegistrationModalArgs {
  isOpen: boolean;
  onClose: () => void;
  subprojectId: string;
  registrationGroups: RegistrationGroups | undefined;
  slidesCount: number | undefined;
}

export const ImageRegistrationModal = ({
  isOpen,
  onClose,
  subprojectId,
  registrationGroups,
  slidesCount,
}: ImageRegistrationModalArgs): ReactElement => {
  const [jobChecked, setChecked] = useState<string | null>(null);
  const { addSnackbar } = useSnackbarMutations();

  const onModalClose = () => {
    setChecked(null);
    onClose();
  };

  const [setRegistration, { loading }] = useMutation(
    SET_SUB_PROJECT_REGISTRATION_MUTATION,
    {
      refetchQueries: [READ_SUBPROJECT_SLIDES],
      onCompleted: (data) => {
        addSnackbar({
          message:
            data.setSubProjectRegistration === slidesCount
              ? 'Image registration coefficient successfully updated.'
              : `Registration coefficient was successfully applied on all ${data.setSubProjectRegistration} eligible slides.`,
          type: 'success',
        });
        onModalClose();
      },
      onError: () => {
        addSnackbar({
          message: `Error happened while trying to apply registration`,
          type: 'error',
        });
      },
    }
  );

  const columnDefs = useMemo<ColDef<RegistrationRow>[]>(
    () => getImageRegistrationModalColDef(),
    []
  );

  const context = {
    jobChecked,
    setChecked,
  };

  return (
    <Modal
      size={'large'}
      isVisible={isOpen}
      onClose={onModalClose}
      aria-labelledby="imageRegistrationModal"
      header={
        <$ModalTitle id="imageRegistrationModal">
          Apply image registration
        </$ModalTitle>
      }
      footer={
        <$ButtonsContainer>
          <Button onClick={onModalClose}>Cancel</Button>
          <Button
            onClick={async () => {
              await setRegistration({
                variables: {
                  subprojectId,
                  registrationGroupId: jobChecked,
                },
              });
            }}
            disabled={jobChecked === null || loading}
          >
            Apply
          </Button>
        </$ButtonsContainer>
      }
    >
      <$ModalWrapper>
        <AGGridTable<RegistrationRow>
          columnDefs={columnDefs}
          rowData={registrationGroups}
          rowModelType="clientSide"
          context={context}
          persistParamsInUrl={false}
          pagination={false}
          domLayout="autoHeight"
        />
      </$ModalWrapper>
    </Modal>
  );
};
