import type { ValidationErrors } from 'final-form';
import { EMPTY_FIELD_ERROR } from './const';
import type {
  FormRowErrors,
  SingleChannelFormRowErrors,
} from './form.state.types';

const E_MESSAGE_MISSING_FIELDS =
  'Some of the required fields are missing. Please make sure all necessary data is provided.';
export const generateGroupedOnboardingErrors = (
  errors: ValidationErrors
): string[] => {
  if (!errors) {
    return [];
  }

  const batchHasMissingFields =
    errors.batch_name || errors.species || errors.association;
  const secondaryFieldsErrors: string[] = [];
  let batchHasSlidesWithMissingFields = false;
  errors.slides?.forEach((data: FormRowErrors) => {
    if (!data) return;
    const error =
      'parentAssigned' in data
        ? (data.parentAssigned as SingleChannelFormRowErrors)
        : (data as SingleChannelFormRowErrors);
    [
      'tissue',
      'section',
      'block',
      'patientExternalId',
      'disease',
      'samplePreparation',
      'sampleType',
    ].forEach((field) => {
      const fieldError = error[field as keyof SingleChannelFormRowErrors];
      if (
        fieldError &&
        fieldError !== EMPTY_FIELD_ERROR &&
        !secondaryFieldsErrors.includes(fieldError)
      ) {
        secondaryFieldsErrors.push(fieldError);
      }
    });
    batchHasSlidesWithMissingFields = Boolean(
      error.staining === EMPTY_FIELD_ERROR ||
        error.caseName === EMPTY_FIELD_ERROR ||
        error.scannerId === EMPTY_FIELD_ERROR ||
        error.tissue === EMPTY_FIELD_ERROR ||
        error.disease === EMPTY_FIELD_ERROR ||
        error.samplePreparation === EMPTY_FIELD_ERROR ||
        error.sampleType === EMPTY_FIELD_ERROR ||
        ('channels' in data && data.channels && data.channels.length > 0)
    );
  });

  return batchHasMissingFields || batchHasSlidesWithMissingFields
    ? [...secondaryFieldsErrors, E_MESSAGE_MISSING_FIELDS]
    : secondaryFieldsErrors;
};
