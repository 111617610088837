import { Checkbox, Flex } from '@aignostics/components';
import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import { parseISO } from 'date-fns';
import React from 'react';

const dateFormatter = new Intl.DateTimeFormat(navigator.languages.slice(), {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

export interface RegistrationRow {
  registrationGroupId: string;
  registrationGroupName: string;
  triggeredAt: string | null;
}

export const getImageRegistrationModalColDef =
  (): ColDef<RegistrationRow>[] => {
    return [
      {
        headerName: 'Coefficient',
        field: 'registrationGroupName',
        flex: 1,
      },
      {
        headerName: 'Date created',
        field: 'triggeredAt',
        flex: 1,
        valueFormatter: (params) => {
          if (!params.value) return '';
          return dateFormatter.format(parseISO(params.value));
        },
      },
      {
        headerName: 'Apply',
        field: 'registrationGroupId',
        flex: 0.5,
        cellRenderer: (props: ICellRendererParams<RegistrationRow>) => {
          const { data, context } = props;
          if (!data) return null;

          return (
            <Flex align="center" height="100%">
              <Checkbox
                checked={data.registrationGroupId === context.jobChecked}
                onCheckedChange={(checked) => {
                  if (checked) {
                    context.setChecked(data.registrationGroupId);
                  } else {
                    context.setChecked(null);
                  }
                }}
              />
            </Flex>
          );
        },
      },
    ];
  };
