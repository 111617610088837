import {
  Button,
  Disclosure,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  HStack,
  Pagination,
  PaginationInfo,
  Section,
  TableSkeleton,
  useFilters,
  usePagination,
  VStack,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useDebounce, useSetQueryParams } from '@aignostics/hooks/lib';
import { Theme } from '@aignostics/theme';
import { contrast, pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, {
  Dispatch,
  forwardRef,
  ReactElement,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { SortByDirection, useSortBy } from '../../../../hooks';
import { SubProject, SubProjectWsisFilterValues, Wsi } from '../../../../types';
import { downloadReport, getQueryParams } from '../../../../utils';
import { SortByOptions } from '../../Subproject.types';
import { AdminSubProjectWsisFilters } from '../AdminSubProjectWsisFilters.component';
import {
  ADMIN_ASSIGNED_SLIDES_PAGE_SIZE,
  refType,
  SubprojectData,
} from './AdminSubProjectSlides.component';
import {
  FilterKeysWithAssociation,
  PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST,
  PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST,
} from './AdminSubprojectSlides.type';
import {
  READ_SUBPROJECT_SLIDES,
  READ_SUBPROJECT_SLIDES_VARIABLES,
} from './READ_SUBPROJECT_SLIDES.queries';
import { SlidesGrid } from './SlidesGrid.component';

type AssignedSlidesGridProps = {
  organizationUuid: string;
  projectId: string;
  subprojectId: string;
  infoModalDisclosure: Disclosure<Wsi>;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  userRole: OrganizationRole;
  assignDialog: Disclosure<unknown>;
  importSlidesDialog: Disclosure<unknown>;
  setSubprojectData: Dispatch<SubprojectData>;
  children: ReactNode;
};

const AIGNOSTICS_ORGANIZATION_NAME = 'aignostics';

const $SectionDescription = styled.div<{
  background: keyof Theme['colors'];
}>`
  ${({ theme }) => theme.fontStyles.base};
  color: ${({ background, theme }) =>
    contrast(theme.colors[background], theme)};
`;

export const AssignedSlidesGrid = forwardRef<refType, AssignedSlidesGridProps>(
  (
    {
      organizationUuid,
      projectId,
      subprojectId,
      infoModalDisclosure,
      rasterTileServerUrl,
      getToken,
      userRole,
      assignDialog,
      importSlidesDialog,
      setSubprojectData,
      children,
    },
    ref
  ): ReactElement => {
    const [page, setPage] = usePagination(getPageFromQueryParams());

    const showAssociations =
      userRole.organization.name === AIGNOSTICS_ORGANIZATION_NAME;

    const queryParamsFilters = getFiltersFromQueryParams(
      showAssociations
        ? PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST
        : PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST
    );

    const { filters, filterProps } = useFilters(
      showAssociations
        ? PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST
        : PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST,
      queryParamsFilters
    );

    const urlParams = getQueryParams(['sortBy', 'sortDirection']);
    const sortByParam = urlParams?.sortBy as SortByOptions;
    const sortDirectionParam = urlParams?.sortDirection as SortByDirection;

    const { sortBy } = useSortBy<SortByOptions>({
      column: sortByParam ?? 'name',
      sortDirection: sortDirectionParam || 'asc',
    });

    const queryParams = useMemo(
      () => ({
        page: page.toString(),
        ...filters,
      }),
      [page, filters]
    );
    useSetQueryParams(queryParams);

    const debouncedFilters = useDebounce(filters, 200);

    const {
      data,
      previousData,
      loading: subProjectSlidesLoading,
      refetch,
    } = useQuery<
      {
        subProject: SubProject;
      },
      READ_SUBPROJECT_SLIDES_VARIABLES
    >(READ_SUBPROJECT_SLIDES, {
      variables: {
        subProjectId: subprojectId,
        page,
        pageSize: ADMIN_ASSIGNED_SLIDES_PAGE_SIZE,
        search: debouncedFilters.searchWsis as string,
        associations:
          (debouncedFilters as Record<FilterKeysWithAssociation, string[]>)
            .associations ?? [],
        batches: debouncedFilters.batches as string[],
        case: debouncedFilters.caseId as string,
        sortBy: sortBy?.column || 'name',
        isAsc: sortBy?.sortDirection === 'asc',
        objectivePowers: (debouncedFilters.objectivePowers as string[]).map(
          (value) => parseFloat(value)
        ),
        scanners: debouncedFilters.scanners as string[],
        stainings: debouncedFilters.stainings as string[],
        tissues: debouncedFilters.tissues as string[],
        diseases: debouncedFilters.diseases as string[],
        annotations:
          debouncedFilters.filterAnnotations as SubProjectWsisFilterValues,
        annotatedBy: debouncedFilters.filterAnnotatedBy as string[],
        annotationCategory:
          debouncedFilters.filterAnnotationCategory as string[],
        overlays: debouncedFilters.filterOverlays as SubProjectWsisFilterValues,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

    const subProject = (data || previousData)?.subProject;

    const wsis = subProject?.wsis?.nodes;

    const loading = subProjectSlidesLoading;

    useEffect(() => {
      if (wsis) {
        setSubprojectData({
          totalWsisCount: subProject?.wsis.pageInfo.totalElements || 0,
          totalAnnotated:
            subProject?.wsis.collectionAttributes.totalAnnotated || 0,
        });
      }
    }, [
      setSubprojectData,
      subProject?.wsis.collectionAttributes.totalAnnotated,
      subProject?.wsis.pageInfo.totalElements,
      wsis,
    ]);

    useImperativeHandle(ref, () => ({
      refetch,
      setPage,
      downloadReport: () => {
        if (subProject !== undefined) downloadReport(subProject);
      },
    }));

    return (
      <Section style={{ padding: 0 }}>
        <AdminSubProjectWsisFilters
          subprojectId={subprojectId}
          filterProps={filterProps}
          onChange={(value) => {
            filterProps.onChange(value);
            setPage(1);
          }}
          showAssociations={showAssociations}
          userRole={userRole}
        />

        <HStack
          spacing="large"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <VStack spacing="small" style={{ flexShrink: '0' }}>
            <PaginationInfo
              totalCount={subProject?.wsis?.pageInfo.totalElements || 0}
              currentPage={page}
              itemsPerPage={ADMIN_ASSIGNED_SLIDES_PAGE_SIZE}
              itemType={pluralize(
                'Slide',
                subProject?.wsis?.pageInfo.totalElements || 0
              )}
              loading={subProject?.wsis?.pageInfo.totalElements === undefined}
            />

            <$SectionDescription background="lighter">
              All slides assigned to this subproject.
            </$SectionDescription>
          </VStack>
          {children}
        </HStack>

        {subProject && wsis ? (
          <VStack
            spacing="12"
            alignItems="center"
            style={{ width: '100%', overflowX: 'scroll', flex: 1 }}
          >
            <SlidesGrid
              organizationUuid={organizationUuid}
              projectId={projectId}
              subProjectId={subprojectId}
              loading={loading}
              wsis={wsis}
              pageSize={48}
              infoModalDisclosure={infoModalDisclosure}
              rasterTileServerUrl={rasterTileServerUrl}
              getToken={getToken}
            />

            {!loading && wsis.length === 0 ? (
              <HStack spacing="12">
                <Button small variant="primary" onClick={assignDialog?.open}>
                  Assign slides
                </Button>
                <Button
                  small
                  variant="primaryOutline"
                  onClick={importSlidesDialog?.open}
                >
                  Import slides
                </Button>
              </HStack>
            ) : null}
          </VStack>
        ) : (
          <TableSkeleton rows={ADMIN_ASSIGNED_SLIDES_PAGE_SIZE + 1} />
        )}
        <HStack justifyContent={'center'}>
          {subProject &&
          subProject.wsis?.pageInfo.totalElements >
            ADMIN_ASSIGNED_SLIDES_PAGE_SIZE &&
          subProject.wsis?.pageInfo.totalPages > 1 ? (
            <Pagination
              currentPage={page}
              totalPages={subProject.wsis?.pageInfo.totalPages}
              onPageChanged={setPage}
            />
          ) : null}
        </HStack>
      </Section>
    );
  }
);

AssignedSlidesGrid.displayName = 'AssignedSlidesGrid';
