import { gql } from '@apollo/client';
import { SubProjectWsisFilterValues } from '../../../../types';

export type READ_SUBPROJECT_SLIDES_VARIABLES = {
  subProjectId: string;
  page: number;
  pageSize: number;
  search: string;
  associations: string[];
  batches: string[];
  case: string;
  objectivePowers: number[];
  scanners: string[];
  stainings: string[];
  tissues: string[];
  diseases: string[];
  sortBy: string | null;
  isAsc: boolean;
  annotations?: SubProjectWsisFilterValues;
  annotatedBy?: string[];
  annotationCategory?: string[];
  overlays?: SubProjectWsisFilterValues;
};

export const READ_SUBPROJECT_SLIDES = gql`
  query GET_ADMIN_SUBPROJECT_SLIDES(
    $subProjectId: ID!
    $page: Int!
    $pageSize: Int!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [ID]!
    $stainings: [ID]!
    $tissues: [ID]!
    $diseases: [String]!
    $sortBy: sortSubprojectByValues
    $isAsc: Boolean!
    $annotations: WsiFilterType!
    $overlays: WsiFilterType!
    $annotatedBy: [ID]
    $annotationCategory: [ID]
  ) {
    subProject(id: $subProjectId) {
      id
      name
      projectId
      showCsv
      wsisCount
      annotationFeature
      annotatedWsisCount
      overlaysCount
      annotationCategories {
        id
        name
      }
      annotators {
        ... on Annotator {
          id
          name
          email
        }
        ... on AnonymousAnnotator {
          id
          name
          email
        }
      }
      wsis(
        page: $page
        pageSize: $pageSize
        associations: $associations
        batches: $batches
        case: $case
        objectivePowers: $objectivePowers
        scanners: $scanners
        stainings: $stainings
        tissues: $tissues
        searchSlides: $search
        diseases: $diseases
        sortBy: $sortBy
        isAsc: $isAsc
        annotations: $annotations
        overlays: $overlays
        annotatedBy: $annotatedBy
        annotationCategory: $annotationCategory
      ) {
        nodes {
          id
          name
          disease
          association
          batchName
          objectivePower
          scanner {
            vendor
            model
          }
          staining
          regionsOfInterestCount
          overlaysCount
          taggersCount
          brightfieldCount
          fluorescenceCount
          isBlackLayer
          patientExternalId
          block
          annotationsCount
          localization
          annotationsData(subProjectId: $subProjectId) {
            annotatedByCount
            annotatedCategoriesCount
          }
          case {
            id
            name
          }
        }
        collectionAttributes {
          totalAnnotated
        }
        pageInfo {
          page
          totalPages
          totalElements
        }
      }
    }
  }
`;
