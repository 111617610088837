export type MetadataUsageRestrictionKey =
  | 'partner_do_not_use'
  | 'partner_inproject_only'
  | 'partner_internal_benchmarking_only'
  | 'partner_inhouse_only'
  | 'partner_unrestricted'
  | 'public_data_analytical_only'
  | 'public_data_unrestricted';

type MetadataUsageRestriction = {
  label: string;
  value: MetadataUsageRestrictionKey;
  description: string;
};

export const METADATA_USAGE_RESTRICTIONS: Record<
  MetadataUsageRestrictionKey,
  MetadataUsageRestriction
> = {
  partner_do_not_use: {
    label: 'Partner Do Not Use',
    value: 'partner_do_not_use',
    description:
      'The batch/slide has been onboarded by the partner themselves, and as such no license for it exists. The batch can be viewed by anyone at Aignostics if there is a “need to know”, but cannot be used for anything else unless explicitly agreed to in writing by the partner.',
  },
  partner_inproject_only: {
    label: 'Partner Inproject Only',
    value: 'partner_inproject_only',
    description:
      'The batch/slide can only be used for the specific project or relationship that it was received for. The batch/slide can be viewed by anyone at Aignostics in the context of the project or partner relationship, but the batch should only be annotated, used as training data, or used for benchmarking and evaluation for the specific project/relationship it was onboarded for.',
  },
  partner_internal_benchmarking_only: {
    label: 'Partner Internal Benchmarking Only',
    value: 'partner_internal_benchmarking_only',
    description:
      'This definition is the same  as “Partner Inproject Only”, except it allows for using the batch/slide for benchmarking and evaluation purposes outside of the project scope for internal purposes, i.e., the dataset may not be disclosed as part of a publication and may not be described as part of a publication.',
  },
  partner_inhouse_only: {
    label: 'Partner Inhouse Only',
    value: 'partner_inhouse_only',
    description:
      'The batch/slide can be used for all internal uses at Aignostics. This includes viewing in Portal, annotating, using as training data for both internal models and models that are shared externally, and using in internal benchmarking and evaluation of models. The batch may also be used for benchmarking and evaluation of models that are published in journal articles or in posters, however partner specific biomarkers/stainings must not be identified in the publication.',
  },
  partner_unrestricted: {
    label: 'Partner Unrestricted',
    value: 'partner_unrestricted',
    description:
      'The batch/slide can be used for all and any uses at Aignostics. This includes viewing in Portal, annotating, using as training data for both internal models and models that are shared externally, using in benchmarking and evaluation of models, using in research as both part of the dataset and in journal articles and posters, and sharing with third parties (although this requires approval from Exec. Management).',
  },
  public_data_analytical_only: {
    label: 'Public Data Analytical Only',
    value: 'public_data_analytical_only',
    description:
      'The batch/slide can be used in relation to analytical models only (i.e. not for use in foundation model training). The batch can be viewed by anyone at Aignostic, however the batch/dataset can only be annotated for analytical models, used as training data for analytical models, and used in the benchmarking and evaluation of analytical models. See the Public Data Use Guidelines for further information.',
  },
  public_data_unrestricted: {
    label: 'Public Data Unrestricted',
    value: 'public_data_unrestricted',
    description:
      'The batch/slide can be used for all and any uses at Aignostics. This includes viewing in Portal, annotating, using as training data for both internal models and models that are shared externally, using in benchmarking and evaluation of models, using in research as both part of the dataset and in journal articles and posters (attribution must be given to the providers of dataset when sharing in publications). See the Public Data Use Guidelines for further information.',
  },
};
