import {
  AGGridTable,
  Link,
  OneLiner,
  useDisclosure,
  VStack,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useApolloClient } from '@apollo/client';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  ModelUpdatedEvent,
  SideBarDef,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import React, {
  Dispatch,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useTheme } from 'styled-components';
import { WsiMetadataModal, WsiThumbnail } from '../../../../../components';
import { Wsi } from '../../../../../types';
import { ThumbnailContainer } from '../../../../SlideLibrary/SlideLibrary.styles';
import { refType, SubprojectData } from '../AdminSubProjectSlides.component';
import { READ_SUBPROJECT_SLIDES } from '../READ_SUBPROJECT_SLIDES.queries';
import {
  AssignedSlidesTableType,
  createServerSideDatasource,
} from './hooks/useSlidesList';
import { $SubTitle, $Title } from './styles';
import { getSlidesListColumnDefs } from './utils/columnDef';

export type CoefficientChangeModalState = {
  wsi: string;
  coefficient: string;
  onConfirm: () => Promise<void>;
};

export type OnSetCoefficient = (
  reference: string,
  origin: string,
  registration: string,
  active: boolean,
  registrationLabel: string,
  updateFunc?: () => void
) => void;

const AssignedSlidesTable = forwardRef<
  refType,
  {
    subprojectId: string;
    showMore?: boolean;
    getWsiHref: (wsiUuid: string) => string;
    role: OrganizationRole;
    getToken: () => Promise<string>;
    rasterTileServerUrl: string;
    setSubprojectData: Dispatch<SubprojectData>;
  }
>(
  (
    {
      subprojectId,
      showMore = false,
      getWsiHref,
      role,
      rasterTileServerUrl,
      getToken,
      setSubprojectData,
    },
    ref
  ): ReactElement => {
    const infoModalDisclosure = useDisclosure<Wsi>(false);
    const theme = useTheme();

    const gridRef = useRef<AgGridReact>(null);

    const client = useApolloClient();

    const datasource = useMemo(
      () =>
        createServerSideDatasource(
          client,
          READ_SUBPROJECT_SLIDES,
          subprojectId
        ),
      [client, subprojectId]
    );
    const columnDefs = useMemo(
      () =>
        getSlidesListColumnDefs({
          client,
          subprojectId,
          infoModalDisclosure,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [client, subprojectId]
    );

    const autoGroupColumnDef = useMemo<ColDef>(
      () => ({
        pinned: 'left',
        field: 'name',
        headerName: 'Slide Name',
        flex: 2,
        minWidth: 300,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        sortable: true,
        cellRenderer: 'agGroupCellRenderer',
        cellStyle: (params) => {
          if (!params.data) return null;
          if (
            params.data.level === 0 &&
            !params.data.fluorescence &&
            params.data.brightField === 1
          ) {
            return {
              paddingLeft: '0px',
            };
          }
          return null;
        },
        cellRendererParams: {
          innerRenderer: (
            props: CustomCellRendererProps<AssignedSlidesTableType, string>
          ) => {
            const { data } = props;
            if (!data) return null;
            return (
              <div
                style={{
                  display: 'flex',
                  gap: `${theme.spacings['16']}px`,
                  height: '100%',
                }}
              >
                <ThumbnailContainer>
                  <WsiThumbnail
                    wsiId={data.id}
                    getToken={getToken}
                    rasterTileServerUrl={rasterTileServerUrl}
                    size="small"
                  />
                </ThumbnailContainer>
                {data.level === 1 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <$Title>{data.name}</$Title>
                    <$SubTitle>{data?.scanner?.vendor}</$SubTitle>
                  </div>
                ) : (
                  <Link href={`${getWsiHref(data.id)}`} target="_blank">
                    <OneLiner>{data.name}</OneLiner>
                  </Link>
                )}
              </div>
            );
          },
        },
      }),
      [getToken, getWsiHref, rasterTileServerUrl, theme.spacings]
    );

    const sideBar = useMemo<
      SideBarDef | string | string[] | boolean | null
    >(() => {
      return {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',

            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      };
    }, []);

    const isServerSideGroup = useCallback(
      (dataItem: AssignedSlidesTableType) => {
        return (
          showMore && (dataItem?.brightField > 1 || dataItem?.fluorescence > 0)
        );
      },
      [showMore]
    );

    const getServerSideGroupKey = useCallback(
      (dataItem: AssignedSlidesTableType) => {
        return dataItem.id;
      },
      []
    );

    useImperativeHandle(
      ref,
      () => ({
        refetch: () => {
          gridRef?.current?.api.refreshServerSide({ purge: true });
        },
        setPage: (page: number) =>
          gridRef?.current?.api.paginationGoToPage(page),
        downloadReport: () => {
          gridRef?.current?.api.exportDataAsCsv();
        },
        getTableApi: () =>
          gridRef?.current?.api as GridApi<AssignedSlidesTableType>,
      }),
      []
    );

    const updateSubprojectData = useCallback(
      (
        props:
          | FirstDataRenderedEvent<AssignedSlidesTableType>
          | ModelUpdatedEvent<AssignedSlidesTableType>
      ) => {
        setSubprojectData({
          totalWsisCount:
            props.api?.getDisplayedRowAtIndex(0)?.data?.totalWsisCount || 0,
          totalAnnotated:
            props.api?.getDisplayedRowAtIndex(0)?.data?.totalAnnotated || 0,
        });
      },
      [setSubprojectData]
    );

    return (
      <>
        <VStack
          spacing="12"
          alignItems="center"
          style={{ width: '100%', overflowX: 'scroll', flex: 1 }}
        >
          <VStack
            style={{
              gap: `${theme.spacings[32]}px`,
              width: '100%',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <div style={{ flex: 1, width: '100%' }}>
              <AGGridTable
                ref={gridRef}
                serverSideDatasource={datasource}
                columnDefs={columnDefs}
                defaultColDef={{
                  minWidth: 150,
                  valueFormatter: (
                    props: ValueFormatterParams<AssignedSlidesTableType>
                  ): string => {
                    if (props.data?.level === 1) return '';
                    return props.value;
                  },
                }}
                sideBar={sideBar}
                onFirstDataRendered={updateSubprojectData}
                autoGroupColumnDef={autoGroupColumnDef}
                treeData
                isServerSideGroup={isServerSideGroup}
                getServerSideGroupKey={getServerSideGroupKey}
                onModelUpdated={updateSubprojectData}
                rowHeight={48}
              />
            </div>
            {infoModalDisclosure.data ? (
              <WsiMetadataModal
                isOpen={infoModalDisclosure.isOpen}
                wsiId={infoModalDisclosure.data.id}
                onClose={infoModalDisclosure.close}
                subProjectId={subprojectId}
                role={role}
                rasterTileServerUrl={rasterTileServerUrl}
                getToken={getToken}
              />
            ) : null}
          </VStack>
        </VStack>
      </>
    );
  }
);

AssignedSlidesTable.displayName = 'AssignedSlidesTable';

export { AssignedSlidesTable };
