import { Batch, Scanner } from '@aignostics/api-shared';
import { Disclosure, IconButton } from '@aignostics/components';
import { OrganizationUser } from '@aignostics/core';
import { ApolloClient } from '@apollo/client';
import {
  ColDef,
  KeyCreatorParams,
  SetFilterValuesFuncParams,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { CustomCellRendererProps } from 'ag-grid-react';
import React from 'react';
import {
  AnnotationCategory,
  Association,
  Disease,
  Staining,
  Tissue,
  Wsi,
} from '../../../../../../types';
import { FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS } from '../../../AdminSubProjectWsisFilters.component';
import { RegistrationSelect } from '../components/RegistrationSelect';
import { AssignedSlidesTableType } from '../hooks/useSlidesList';

type GetColumnsDefArgs = {
  client: ApolloClient<object>;
  subprojectId: string;
  infoModalDisclosure: Disclosure<Wsi>;
};

export const getSlidesListColumnDefs = ({
  client,
  subprojectId,
  infoModalDisclosure,
}: GetColumnsDefArgs): ColDef<AssignedSlidesTableType>[] => {
  return [
    {
      field: 'id',
      headerName: 'Info',
      sortable: true,
      minWidth: 100,
      cellRenderer: (
        props: CustomCellRendererProps<AssignedSlidesTableType, string>
      ) => {
        const { data } = props;

        if (data?.level === 1 || !data) return null;
        return (
          <IconButton
            description="Open slide metadata"
            icon="FileText"
            size="button"
            onClick={() => {
              infoModalDisclosure.open(props?.data?.wsiData);
            }}
          />
        );
      },
    },
    {
      field: 'brightField',
      headerName: 'Brightfield',
      sortable: true,
      cellStyle: (params) => {
        if (!params.data) return null;
        if (params.data.level === 1) {
          return {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'visible',
          };
        }
        return null;
      },
      cellRenderer: (
        props: CustomCellRendererProps<AssignedSlidesTableType, string>
      ) => {
        const { data } = props;

        if (!data) return <></>;

        if (data.level === 1) {
          return (
            <RegistrationSelect subprojectId={subprojectId} props={props} />
          );
        }
        return <>{props.value}</>;
      },
    },
    {
      field: 'fluorescence',
      headerName: 'Fluorescence',
      sortable: true,
    },
    {
      field: 'annotations',
      headerName: 'Annotations',
      sortable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['with', 'without'],
      },
    },
    {
      field: 'taggers',
      headerName: 'Interactive Overlays',
      sortable: true,
    },
    {
      field: 'overlays',
      headerName: 'Static Overlays',
      sortable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['with', 'without'],
      },
    },
    {
      field: 'id',
      headerName: 'Slide Id',
      sortable: false,
      initialHide: true,
      minWidth: 350,
    },
    {
      field: 'association',
      headerName: 'Association',
      sortable: false,
      initialHide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,
        values: async (params: SetFilterValuesFuncParams) => {
          const { data: filtersData } = await client.query({
            query: FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
            variables: {
              subProjectId: subprojectId,
              associations: [],
            },
            fetchPolicy: 'cache-first',
          });

          if (!filtersData?.associations) {
            params.success([]);
            return;
          }

          params.success(filtersData.associations);
        },

        keyCreator: (params: KeyCreatorParams<Association>) => {
          return params.value.uuid;
        },
        valueFormatter: (params: ValueFormatterParams<Association>) =>
          params.value.name,
      },
    },
    {
      field: 'batch',
      headerName: 'Batch',
      sortable: false,
      initialHide: true,
      minWidth: 250,
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,

        values: async (params: SetFilterValuesFuncParams) => {
          const { data: filtersData } = await client.query({
            query: FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
            variables: {
              subProjectId: subprojectId,
              associations: [],
            },
            fetchPolicy: 'cache-first',
          });

          if (!filtersData?.batches) {
            params.success([]);
            return;
          }

          params.success(filtersData.batches);
        },
        keyCreator: (params: KeyCreatorParams<Batch>) => {
          return params.value.uuid;
        },
        valueFormatter: (params: ValueFormatterParams<Batch>) =>
          params.value.name,
      },
    },
    {
      field: 'case',
      headerName: 'Case',
      sortable: false,
      initialHide: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
    },
    {
      field: 'objectivePower',
      headerName: 'Objective Power',
      sortable: false,
      initialHide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,
        values: [20, 40],
      },
    },
    {
      field: 'scanner',
      headerName: 'Scanner',
      sortable: false,
      initialHide: true,
      filter: 'agSetColumnFilter',
      valueGetter: (props) =>
        `${props.data?.scanner.model ?? ''} ${props.data?.scanner.vendor}`,
      filterParams: {
        defaultToNothingSelected: true,

        values: async (params: SetFilterValuesFuncParams) => {
          const { data: filtersData } = await client.query({
            query: FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
            variables: {
              subProjectId: subprojectId,
              associations: [],
            },
            fetchPolicy: 'cache-first',
          });

          if (!filtersData?.scanners) {
            params.success([]);
            return;
          }

          params.success(filtersData.scanners);
        },
        keyCreator: (params: KeyCreatorParams<Scanner>) => {
          return params.value.uuid;
        },
        valueFormatter: (params: ValueFormatterParams<Scanner>) =>
          `${params.value.model ?? ''} ${params.value.vendor}`,
      },
    },
    {
      field: 'staining',
      headerName: 'Staining',
      sortable: false,
      initialHide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,

        values: async (params: SetFilterValuesFuncParams) => {
          const { data: filtersData } = await client.query({
            query: FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
            variables: {
              subProjectId: subprojectId,
              associations: [],
            },
            fetchPolicy: 'cache-first',
          });

          if (!filtersData?.stainings) {
            params.success([]);
            return;
          }

          params.success(filtersData.stainings);
        },
        keyCreator: (params: KeyCreatorParams<Staining>) => {
          return params.value.uuid;
        },
        valueFormatter: (params: ValueFormatterParams<Staining>) =>
          params.value.name,
      },
    },
    {
      field: 'localization',
      headerName: 'Localization',
      sortable: false,
      initialHide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,

        values: async (params: SetFilterValuesFuncParams) => {
          const { data: filtersData } = await client.query({
            query: FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
            variables: {
              subProjectId: subprojectId,
              associations: [],
            },
            fetchPolicy: 'cache-first',
          });

          if (!filtersData?.tissues) {
            params.success([]);
            return;
          }

          params.success(filtersData.tissues);
        },
        keyCreator: (params: KeyCreatorParams<Tissue>) => {
          return params.value.uuid;
        },
        valueFormatter: (params: ValueFormatterParams<Tissue>) =>
          params.value.name,
      },
    },
    {
      field: 'disease',
      headerName: 'Disease',
      sortable: false,
      initialHide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,

        values: async (params: SetFilterValuesFuncParams) => {
          const { data: filtersData } = await client.query({
            query: FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
            variables: {
              subProjectId: subprojectId,
              associations: [],
            },
            fetchPolicy: 'cache-first',
          });

          if (!filtersData?.diseases) {
            params.success([]);
            return;
          }

          params.success(filtersData.diseases);
        },
        keyCreator: (params: KeyCreatorParams<Disease>) => {
          return params.value.name;
        },
        valueFormatter: (params: ValueFormatterParams<Disease>) =>
          params.value.name,
      },
    },
    {
      field: 'annotatedBy',
      headerName: 'Annotated By',
      sortable: false,
      initialHide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,

        values: async (params: SetFilterValuesFuncParams) => {
          const { data: filtersData } = await client.query({
            query: FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
            variables: {
              subProjectId: subprojectId,
              associations: [],
            },
            fetchPolicy: 'cache-first',
          });

          if (!filtersData?.subProject?.annotators) {
            params.success([]);
            return;
          }

          params.success(filtersData.subProject?.annotators);
        },
        keyCreator: (params: KeyCreatorParams<OrganizationUser>) => {
          return params.value.id;
        },
        valueFormatter: (params: ValueFormatterParams<OrganizationUser>) =>
          params.value.name,
      },
    },

    {
      field: 'annotationCategory',
      headerName: 'Annotation categories',
      sortable: false,
      initialHide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,

        values: async (params: SetFilterValuesFuncParams) => {
          const { data: filtersData } = await client.query({
            query: FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
            variables: {
              subProjectId: subprojectId,
              associations: [],
            },
            fetchPolicy: 'cache-first',
          });

          if (!filtersData?.subProject?.annotationCategories) {
            params.success([]);
            return;
          }

          params.success(filtersData.subProject?.annotationCategories);
        },
        keyCreator: (params: KeyCreatorParams<AnnotationCategory>) => {
          return params.value.id;
        },
        valueFormatter: (params: ValueFormatterParams<AnnotationCategory>) =>
          params.value.name,
      },
    },
  ] satisfies ColDef<AssignedSlidesTableType>[];
};
